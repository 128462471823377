import AllergiesTemplate from '@/modules/questionnaire/components/steps/common/medical-background/allergies/AllergiesTemplate';

import { ALLERGY, NONE } from '@/modules/questionnaire/api/constants';

export default {
  title: 'Steps/MedicalBackground/Page12Allergies/AllergiesTemplate',
  component: AllergiesTemplate
};

const createStory = props => () => ({
  components: { AllergiesTemplate },
  storyProps: props,
  wrapperStyles: {
    display: 'flex',
    justifyContent: 'center'
  },
  template:
    '<div :style="$options.wrapperStyles"><allergies-template v-bind="$options.storyProps" /></div>'
});

export const EnvironmentalAndFood = createStory({
  allergyTypes: [ALLERGY.ENVIRONMENTAL, ALLERGY.FOOD]
});

export const None = createStory({
  allergyTypes: [NONE]
});
